import * as React from "react";
import { withPrefix } from "gatsby";
import { Helmet } from "react-helmet";
import background from "../../images/services/surveillance-evenementielle.jpg";
import "../../scss/style.scss";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServicesListBasic from "../../components/services/ServicesListBasic";
import ContactUs from "../../components/buttons/ContactUs";

const SecuriteEvenementielle = () => {
  return (
    <main className="page">
      <title>
        Société de sécurité spécialisée dans votre protection - Services -
        Sécurité Évènementielle
      </title>
      <div id="wrapper" className="wrapper">
        <Header />
        <Banner
          category="Services"
          title="Sécurité Évènementielle"
          content=""
          background={background}
          position="left"
        />
        <div className="container pt-4 pt-md-10 pb-4 pb-md-10">
          <div className="row justify-content-start">
            <div className="col-md-8">
              <div className="service service-single">
                <div className="content">
                  <h1>
                    Service de sécurité évènementielle pour des événements
                    réussis
                  </h1>
                  <p>
                    Notre service de sécurité évènementielle est conçu pour
                    garantir que votre événement se déroule en toute sécurité.
                    Nous savons que la sécurité est un élément crucial pour la
                    réussite de tout événement et nous sommes là pour fournir
                    des <em>services de sécurité</em> professionnels pour votre
                    tranquillité d'esprit.
                  </p>
                  <h2>Notre approche de la sécurité évènementielle:</h2>
                  <p>
                    Nous offrons une approche personnalisée pour la{" "}
                    <em>sécurité de votre événement</em>. Nous travaillons en
                    étroite collaboration avec vous pour comprendre les besoins
                    de votre événement et élaborer un plan de sécurité complet
                    pour répondre à ces besoins. Nos{" "}
                    <em>agents de sécurité qualifiés</em> sont formés pour
                    assurer la sécurité de votre événement en tout temps et sont
                    équipés des dernières technologies de surveillance pour une
                    sécurité maximale.
                  </p>
                  <h2>Nos services de sécurité évènementielle:</h2>
                  <p>
                    Nous offrons une gamme complète de{" "}
                    <em>services de sécurité évènementielle</em> pour répondre à
                    vos besoins spécifiques, notamment :
                  </p>
                  <ul>
                    <li>
                      <em>Contrôle d'accès</em>
                    </li>
                    <li>
                      <em>Surveillance de foule</em>
                    </li>
                    <li>
                      <em>Surveillance des équipements</em>
                    </li>
                    <li>
                      <em>Gestion de la sécurité</em>
                    </li>
                    <li>
                      <em>Sécurité VIP</em>
                    </li>
                    <li>
                      <em>Sécurité de la scène</em>
                    </li>
                  </ul>
                  <h2>Notre équipe de sécurité évènementielle:</h2>
                  <p>
                    Nous sommes fiers de dire que notre équipe de{" "}
                    <em>sécurité évènementielle</em> est composée d'agents de
                    sécurité hautement qualifiés et expérimentés. Tous nos
                    agents de sécurité sont formés pour faire face à toutes les
                    situations d'urgence possibles et pour agir rapidement et
                    efficacement en cas de besoin. Ils sont également équipés
                    des dernières technologies de surveillance pour garantir que
                    votre événement est surveillé en tout temps.
                  </p>
                  <h2>
                    Pourquoi choisir notre service de sécurité évènementielle:
                  </h2>
                  <p>
                    Nous sommes engagés à fournir un service de sécurité
                    évènementielle de qualité supérieure à tous nos clients.
                    Nous travaillons en étroite collaboration avec vous pour
                    garantir que votre événement se déroule en toute sécurité.
                    Notre approche personnalisée, notre équipe de sécurité
                    qualifiée et nos technologies de pointe garantissent une
                    sécurité maximale de votre événement.
                  </p>
                  <p>
                    La sécurité de votre événement est notre priorité numéro un.
                    Nous offrons une gamme complète de{" "}
                    <em>services de sécurité évènementielle personnalisés</em>{" "}
                    pour répondre à vos besoins spécifiques. Faites confiance à
                    nos agents de sécurité qualifiés et expérimentés pour
                    garantir que votre événement se déroule en toute sécurité.
                    Contactez-nous dès aujourd'hui pour discuter de vos besoins
                    en matière de sécurité évènementielle
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-2">
                <ContactUs theme="black" />
              </div>
            </div>
            <div className="col-md-3 offset-md-1 pt-4">
              <h3>Nos prestations :</h3>
              <ServicesListBasic />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};
export default SecuriteEvenementielle;
